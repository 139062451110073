$( '.image-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		fadeEffect: { crossFade: true },
		autoplay: {
			delay: $this.attr( 'data-timeout' ),
			disableOnInteraction: false,
		},
		slidersPerView: 1,
		effect: 'fade',
		noSwiping: true,
	} );
} );

