import {FilterData, FilteringFlow} from "@filtering.js/filtering.js/ui";
import {animateInViewport} from "../transition";
import {updateGoogleMapMarkers} from "./map";
import {loadMore} from "../load-more";
import {esc_title} from "../utils";

const filterCheckedClass = 'active';

$( function() {
	var $filtering = $( '.filtering' );
	if ( $filtering.length ) {
		var filteringElement = $filtering[ 0 ];

		function updateFilterContainer( $this ) {
			$filtering.find( '.filter-items-container' )
				.stop().slideUp( 'fast' )
				.removeClass( 'expanded' );
			$filtering.find( '.filter-group' ).find( '.filter-group-arrow' )
				.removeClass( '-rotate-90' ).addClass( 'rotate-90' );
			if ( $this ) {
				$this.parents( '.filter-group' ).find( '.filter-items-container' )
					.stop().slideDown( 'fast' )
					.addClass( 'expanded' );
				$this.parents( '.filter-group' ).find( '.filter-group-arrow' )
					.removeClass( 'rotate-90' ).addClass( '-rotate-90' );
			}
		}

		const $search = $( filteringElement ).find( 'input[type="search"]' );
		$search.on( 'keyup search', ( event ) => {
			filteringFlow.filter();
		} );

		class MyFilteringFlow extends FilteringFlow {

			get parserOptions() {
				return { filterCheckedClass };
			}

			get filteringOptions() {
				return {
					filterItem: function( item, schema ) {
						if ( $search.length ) {
							const searchWords = $search.val()
								.split( /\s+/g )
								.filter( ( w ) => w !== '' )
								.map( esc_title );
							const $item = $( item.data.element );
							const itemSearch = esc_title( $item.attr( 'data-search-text' ) );
							return searchWords.every( ( searchWord ) => {
								return itemSearch.indexOf( searchWord ) !== -1;
							} );
						} else {
							return true;
						}
					},
				};
			}

			handleFilterResult( result, filterData ) {
				super.handleFilterResult( result );

				const isOnlyBoardFilterSelected = filterData.checkedFilters.has( 'tag' )
					&& filterData.checkedFilters.get( 'tag' ).size === 1
					&& filterData.checkedFilters.get( 'tag' ).has( 'board' );
				$filtering.find( '.board-image' ).toggleClass( '!mt-0', isOnlyBoardFilterSelected );

				filteringElement.querySelectorAll( '.no-results' ).forEach( ( noResultElement ) => {
					const $noResultElement = $( noResultElement );
					if ( result.filteredItems.length > 0 ) {
						$noResultElement.removeClass( 'active' );
					}
					$noResultElement.toggleClass( 'hidden', result.filteredItems.length > 0 );
				} );

				this.updateLoadMore( result );

				let allFilterChecked = true;
				for ( const groupResult of result.groups ) {
					const groupElement = groupResult.schemaGroup.data.element;
					const $filteringGroupTitle = $( groupElement ).find( '.filtering-group-title' );
					let showingAllFilter = true;
					for ( const filterResult of groupResult.filters ) {
						const filterElement = filterResult.schemaFilter.data.element;

						const totalElement = filterElement.querySelector( '.filtering-total' );
						if ( totalElement ) {
							totalElement.innerText = filterResult.possibleItems.length;
						}

						if ( filterElement.classList.contains( filterCheckedClass ) ) {
							if ( filterElement.dataset.filterType !== 'all' ) {
								$filteringGroupTitle.html( filterElement.innerHTML );
								showingAllFilter = false;
							}
						}

						if ( filterElement.dataset.filterType !== 'all' && filterElement.classList.contains( filterCheckedClass ) ) {
							allFilterChecked = false;
						}
					}
					if ( showingAllFilter ) {
						$filteringGroupTitle.html( $filteringGroupTitle.attr( 'data-default-title' ) );
					}
					$( groupElement ).find( '[data-filter-type="all"]' ).toggleClass( filterCheckedClass, showingAllFilter );
				}

				$( '*[data-filter-type="all"]' ).toggleClass( 'hidden', filterData.checkedFilters.size === 0 );

				updateFilterContainer();
				this.updateItems( result );
				this.updateItemClasses( result );
				window.msnry.masonry();
				setTimeout( animateInViewport, 0 );

				$( '.enable-all-filters' ).toggleClass( filterCheckedClass, allFilterChecked );

				this.updateGoogleMarkers( result );

				storeFiltersToHash( filterData );
			}

			updateLoadMore( result ) {
				if ( $( '.load-more', filteringElement ).length === 0 ) {
					return;
				}
				$( '.load-more-container' ).toggleClass( 'hidden', result.filteredItems.length === 0 );

				for ( const item of result.allItems ) {
					item.data.element.classList.add( 'load-more-hidden' );
				}
				loadMore( $( '.load-more' ) );
			}

			updateGoogleMarkers( result ) {
				const ids = [];
				for ( const item of result.filteredItems ) {
					ids.push( item.data.element.id );
				}
				updateGoogleMapMarkers( ids );
			}

			updateItems( result ) {
				const specialFiltering = $filtering.attr( 'data-special-filtering' );
				if ( specialFiltering === 'employees' ) {
					$filtering.find( '.employee-placeholder' ).remove();
					let index = 0;
					for ( const item of $( filteringElement ).find( '.filtering-item:not(.hidden)' ) ) {
						if ( index++ % 6 === 2 ) {
							index++;
							const $item = $( item );
							$( '<div class="filtering-item employee-placeholder"></div>' ).insertBefore( $item );
						}
					}
				}
			}

			updateItemClasses() {
				let shownClasses = $filtering.attr( 'data-shown-classes' );
				if ( !shownClasses ) {
					return;
				}
				shownClasses = shownClasses.split( ',' );
				for ( const [itemIndex, item] of [...$( filteringElement ).find( '.filtering-item:not(.hidden)' )].entries() ) {
					const $item = $( item );
					for ( const [delayClassIndex, delayClass] of shownClasses.entries() ) {
						$item.toggleClass( delayClass, itemIndex % shownClasses.length === delayClassIndex );
					}
					$item.removeClass( 'wait-to-animate' );
				}
			}
		}

		$( document ).click( function( event ) {
			if ( !$( event.target ).closest( '.filter-group' ).length ) {
				updateFilterContainer();
			}
		} );

		$filtering.find( '.filter-group .filter-group-toggle' ).on( 'click', function() {
			var $this = $( this );
			if ( $this.parents( '.filter-group' ).find( '.filter-items-container' ).hasClass( 'expanded' ) ) {
				updateFilterContainer();
			} else {
				updateFilterContainer( $this );
			}
		} );

		const storeHash = filteringElement.dataset.storeHash === 'true';
		const filteringFlow = new MyFilteringFlow( filteringElement, {
			filteredItemClass: 'hidden',
			triggerFilterAfterInitializing: !storeHash,
		} );
		window.addEventListener( 'hashchange', loadFiltersFromHash );
		if ( storeHash ) {
			loadFiltersFromHash();
		}

		function loadFiltersFromHash() {
			if ( !storeHash ) {
				return;
			}

			const hash = window.location.hash.substring( 1 );
			const filteringData = new FilterData();
			if ( hash ) {
				const filteringAsQueryParams = new URLSearchParams( hash );
				for ( const [groupName, filterName] of filteringAsQueryParams ) {
						filteringData.checkFilter( groupName, filterName );
				}
			}
			filteringFlow.filter( filteringData );
		}

		function storeFiltersToHash( filterData ) {
			if ( !storeHash ) {
				return;
			}

			const filteringAsQueryParams = new URLSearchParams();
			for ( const [groupName, checkedFilters] of filterData.checkedFilters ) {
				for ( const checkedFilter of checkedFilters ) {
					filteringAsQueryParams.append( groupName, checkedFilter );
				}
			}

			const newHash = filteringAsQueryParams.toString();
			updateHash( newHash );
		}

		function updateHash( newHash ) {
			if ( newHash !== window.location.hash.substring( window.location.hash.indexOf( '#' ) + 1 ) ) {
				if ( newHash ) {
					window.location.hash = newHash;
				} else {
					const uri = window.location.toString();
					history.pushState( true, '', uri.substring( 0, uri.indexOf( "#" ) ) );
				}
			}
		}
	}
} );
